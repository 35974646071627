/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import adapter from "webrtc-adapter";
import hat from "hat";
import { Howl } from "howler";
import Janus from "./janus.js";
import config from "./config";
import tono from "./ringtone.wav";
import store from "../../../store";

export default class PhoneCommander {
  constructor(
    userConfig,
    debug,
    videoTagRef,
    audioTagRef,
    onIncomingCall,
    cleanUp
  ) {
    this.debug = debug;
    this.userConfig = userConfig;
    this.janus = undefined;
    this.sipCall = undefined;
    this.jsep = undefined;
    this.registered = "";
    this.llamadaEstado = "false";
    this.videoTagRef = videoTagRef;
    this.audioTagRef = audioTagRef;
    this.onIncomingCall = onIncomingCall;
    this.cleanUp = cleanUp; // when remote or local hangup callback
    this.offerlessInvite = false;
    this.doAudio = true;
    this.doVideo = true;
    this.localTracks = {};
    this.ringtone = new Howl({
      src: [tono],
      loop: true,
    });
  }

  sessionCreated(pluginHandle) {
    this.sipCall = pluginHandle;
    const register = {
      request: "register",
      username: this.userConfig.sipIdentity,
      display_name: this.userConfig.displayName,
      proxy: this.userConfig.sipRegistrar,
      authuser: this.userConfig.username,
      secret: this.userConfig.secret,
    };
    this.sipCall.send({ message: register });
    this.registered = true;
  }

  onMessage(msg, jsep) {
    this.jsep = jsep;
    Janus.debug(" ::: Got a message :::", msg);
    const { error, callId, result } = msg;

    if (error) {
      // Reset status
      if (this.registered) this.sipCall.hangup();
      alert(error);
      console.log(error);
      return;
    }

    const { event } = result;

    if (!result || !event) return;
    if (event === "registration_failed") {
      Janus.warn(`Registration failed: ${result.code} ${result.reason}`);
      store.commit("SET_REGISTRO", false);
      return;
    }

    if (event === "registered") {
      Janus.log(`Successfully registered as ${result.username}!`);
      if (!this.registered) {
        this.registered = true;
      }
      store.commit("SET_REGISTRO", true);
      return;
    }

    if (event === "calling") {
      Janus.log("Waiting for the peer to answer...");
    }

    if (event === "incomingcall") this.incomingCall(msg.call_id, result, jsep);

    if (event === "progress" && this.jsep) {
      this.sipCall.handleRemoteJsep({
        jsep: this.jsep,
        error: (e) => {
          console.log(e);
          this.doHangup();
        },
      });
    }

    if (event === "notify") {
      store.commit("SET_LLAMADAESTADO", "transferenciaNotificada");
    }

    if (event === "hangup") {
      store.commit("SET_LLAMADAESTADO", "intermedio");
      store.commit("SET_LLAMADAESTADO", "hangup");
      this.llamadaEstado = "hangup";
    }

    if (event === "accepted") {
      store.commit("SET_LLAMADAESTADO", "intermedio");
      store.commit("SET_LLAMADAESTADO", "accepted");
      this.llamadaEstado = "accepted";
      if (this.jsep) {
        this.sipCall.handleRemoteJsep({
          jsep: this.jsep,
          error: () => this.doHangup(),
        });
      }
      this.sipCall.callId = msg.call_id;
    }

    if (event === "updatingcall") {
      this.doAudio = this.jsep.sdp.indexOf("m=audio ") > -1;
      this.doVideo = this.jsep.sdp.indexOf("m=video ") > -1;
      let tracks = [];
      tracks.push({ type: "audio", capture: true, recv: true });
      tracks.push({ type: "video", capture: true, recv: true });

      this.sipCall.createAnswer({
        jsep: this.jsep,
        tracks: tracks,
        success: (IncomingJsep) => {
          Janus.debug(
            `Got SDP ${this.jsep.type}! audio=${this.doAudio}, video=${this.doVideo}:`,
            IncomingJsep
          );
          const body = { request: "update" };
          this.sipCall.send({ message: body, jsep: IncomingJsep });
        },
        error: (error) => {
          Janus.error("WebRTC error:", error);
          const body = { request: "decline", code: 480 };
          this.sipCall.send({ message: body });
        },
      });
    }
  }

  onLocalTrack(track, on) {
    if (
      this.sipCall.webrtcStuff.pc.iceConnectionState === "completed" &&
      this.sipcall.webrtcStuff.pc.iceConnectionState === "connected"
    ) {
      this.onWaitingResponse(false);
    }
  }

  onRemoteTrack(track, mid, on) {
    if (track.kind === "audio") {
      var audio = this.audioTagRef;
      var stream = new MediaStream([track]);
      Janus.attachMediaStream(audio, stream);
    } else {
      var video = this.videoTagRef;
      var stream = new MediaStream([track]);
      Janus.attachMediaStream(video, stream);
    }
    video.onloadedmetadata = function(e) {
      video.play();
    };
  }

  onCleanUp() {
    Janus.log(" ::: Got a cleanup notification :::");
    if (this.ringtone.playing()) this.ringtone.stop();
    if (this.sipCall && this.sipCall.callId)
      this.sipCall = { ...this.sipCall, callId: null };
  }

  attachPlugin() {
    this.janus.attach({
      plugin: "janus.plugin.sip",
      opaqueId: `siptest-${hat()}`,
      success: this.sessionCreated.bind(this),
      error: (error) => Janus.error("  -- Error attaching plugin...", error),
      consentDialog: () => Janus.log("consent dialog"),
      iceState: (state) => Janus.log(`ICE state changed to ${state}`),
      mediaState: (medium, on) =>
        Janus.log(
          `Janus ${on ? "started" : "stopped"} receiving our ${medium}`
        ),
      webrtcState: (on) =>
        Janus.log(
          `Janus says our WebRTC PeerConnection is ${on ? "up" : "down"} now`
        ),
      onmessage: this.onMessage.bind(this),
      onlocaltrack: this.onLocalTrack.bind(this),
      onremotetrack: this.onRemoteTrack.bind(this),
      oncleanup: this.onCleanUp.bind(this),
    });
  }

  createSession() {
    if (!Janus.isWebrtcSupported()) {
      console.error("No WebRTC support...");
      return;
    }
    const janusInstance = new Janus({
      server: config.webrtcServer,
      success: this.attachPlugin.bind(this),
      error: (error) => Janus.error(error),
      destroyed: () => console.log("destroyed"),
    });
    this.janus = janusInstance;
  }

  acceptCall() {
    const sipcallAction = this.offerlessInvite
      ? this.sipCall.createOffer
      : this.sipCall.createAnswer;

    if (this.ringtone.playing()) this.ringtone.stop();

    sipcallAction({
      jsep: this.jsep,
      tracks: [
        { type: "audio", capture: true, recv: true },
        { type: "video", capture: true, recv: true },
      ],
      success: (IncomingJsep) => {
        Janus.debug(
          `Got SDP ${this.jsep.type}! audio=${this.doAudio}, video=${this.doVideo}:`,
          IncomingJsep
        );
        const body = { request: "accept" };
        this.sipCall.send({ message: body, jsep: IncomingJsep });
      },
      error: (error) => {
        Janus.error("WebRTC error:", error);
        const body = { request: "decline", code: 480 };
        this.sipCall.send({ message: body });
      },
    });
  }

  declineCall() {
    if (this.ringtone.playing()) this.ringtone.stop();
    const body = { request: "decline" };
    this.sipCall.send({ message: body });
  }

  hold() {
    const body = {
      request: "hold",
    };
    this.sipCall.send({ message: body });
  }

  unhold() {
    const body = {
      request: "unhold",
    };
    this.sipCall.send({ message: body });
  }

  doHangup() {
    if (this.ringtone.playing()) this.ringtone.stop();
    const hangupMsg = { request: "hangup" };
    this.sipCall.send({ message: hangupMsg });
    this.sipCall.hangup();
  }

  establishingCall(jsep) {
    Janus.debug("Got SDP!", jsep);
    this.jsep = jsep;
    const body = {
      request: "call",
      uri: this.callingUri,
      autoaccept_reinvites: false,
    };
    this.sipCall.send({ message: body, jsep });
  }

  doCall(uri, doVideo, referId) {
    this.callingUri = uri;
    let tracks = [];
    tracks.push({ type: "audio", capture: true, recv: true });
    this.sipCall.createOffer({
      tracks: tracks,
      success: this.establishingCall.bind(this),
      error: (error) => {
        console.log(`WebRTC error... ${error.message}`);
      },
    });
  }

  disconnect() {
    this.janus.destroy();
    store.commit("SET_REGISTRO", false);
  }

  connect() {
    Janus.init({
      debug: this.debug,
      dependencies: Janus.useDefaultDependencies({ adapter }),
      callback: this.createSession.bind(this),
    });
  }

  dtmfEnvio(tecla) {
    if (this.llamadaEstado == "accepted") {
      const body = {
        request: "dtmf_info",
        digit: tecla.toString(),
      };
      this.sipCall.send({ message: body });
    }
  }
  isConnected() {
    return this.janus.isConnected();
  }

  reconnect() {
    this.janus.reconnect();
  }
  getSessionId() {
    return this.janus.getSessionId();
  }
}
