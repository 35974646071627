<template>
  <v-container style="text-align: center">
    <v-row>
      <v-col cols="12">
        <video
          v-show="!verBotonLlamar && verEtiquetaVideo"
          ref="etiquetaVideo"
          class="video"
        />
      </v-col>
      <v-col
        v-show="!verBotonLlamar"
        cols="12"
        :class="{ botonColgarEnLlamada: verBotonColgarEnLlamada }"
      >
     
      <v-img
          v-show="!verGifEspera"
          src="../../assets/ultspin.gif"
          alt="gift espera"
          
></v-img>

      </v-col>
    </v-row>
    <div id="header" v-show="verBotonLlamar">
      <div id="boton_llamada">
        <img
          @click="llamar()"
          src="../../assets/Boton Assist.gif"
          alt="boton llamar"
          style="width: 300px !important"
        />
      </div>
      
    </div>
    
    
<!--
    <v-dialog v-model="verTeclado" width="380px">
      <v-card class="dialogoTeclado">
        <numericpad />
        <v-container>
          <v-row class="justify-center" style="padding-bottom: 5%">
            <v-btn icon color="error" fab x-large @click="cerrarTeclado()">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>-->
    <audio ref="etiquetaAudio" autoplay />
    <v-row>
      <v-snackbar
        v-model="snackbar"
        :bottom="true"
        :right="true"
        :timeout="3000"
        :color="snack_color"
      >
        {{ snack_text }}
      </v-snackbar>
    </v-row>
  </v-container>
</template>
<script>
import PhoneCommander from "./phone/phoneCommander";
import numericpad from "./phone/numeric-keypad.vue";
export default {
  components: {
    numericpad,
  },
  data: () => ({
    snackbar: false,
    snack_color: "",
    snack_text: "",
    myInstance: "",
    verBotonLlamar: true,
    verBotonColgarEnLlamada: false,
    verSpin: false,
    verTeclado: false,
    verEtiquetaVideo: false,
    urlProvision: process.env.VUE_APP_URL_PROVISION,
    urlLoginProvision: process.env.VUE_APP_URL_LOGIN_PROVISION,
    verGifEspera: false, 
    sipIdentity: "",
    displayName: "",
    sipRegistrar: "",
    token:"",
    username: "",
    password: "",
    sipIdentityDestino: "",
  }),
  props: {
    modoDebug: { default: false },
  },
  methods: {
    abrirTeclado() {
      this.verTeclado = true;
    },
    cerrarTeclado() {
      this.verTeclado = false;
    },
    llamar() {
      this.verEtiquetaVideo = false;
      this.$store.getters.getinstanciaPhone.doCall(this.sipIdentityDestino);
      this.verBotonLlamar = false;
      this.verGiftEspera = true;

    },
    colgar() {
      this.$store.getters.getinstanciaPhone.doHangup();
      this.verBotonLlamar = true;
    },
    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
    loginDatosProvision(){

    },
    // login contra la api de provision
    loginProvision() {
      const datos_usuario = {
        email:  process.env.VUE_APP_EMAIL_PROVISION,
        password: process.env.VUE_APP_PASSWORD_PROVISION,
      };
      const goLogin = async () => {
        let config = {
          headers: {
            Accept: "application/json",
          },
        };
        try {
          return await this.$axios.post(
            this.urlLoginProvision,
            datos_usuario,
            config
          );
        } catch (error) {
          console.log("Se ha producido un error al loguearse en el sistema de provisión");
          console.log(error);
        }
      };
      const processgoLoginResult = async () => {
        const login_result = await goLogin();
        if (login_result) {
          this.token=login_result.data.access_token
          this.datosProvision()
        }
      };
      processgoLoginResult();
    },

    // Obtiene los datos de provision
    datosProvision() {
      let ruta = this.$route.query.id // Obtiene el parametro id de la url 
      const getDatosProvision = async () => {
        let config = {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer "+ this.token,
          },
        };
        try {
          return await this.$axios.get(this.urlProvision + "/" + ruta, config);
        } catch (error) {
          console.log("Se ha producido un error al obtener los datos de Provision");
          console.log(error);
        }
      };
      const procesarDatosProvision = async () => {
        const get_result = await getDatosProvision();
        if (get_result) {
          this.sipIdentity = "sip:"+get_result.data.data.sip_identity;
          this.displayName = get_result.data.data.display_name;
          this.sipRegistrar = "sip:"+get_result.data.data.sip_registar;
          this.username = get_result.data.data.username;
          this.password = get_result.data.data.password;
          this.sipIdentityDestino = "sip:"+get_result.data.data.sip_identity_destino;
          this.registrar();
        }
      };
      procesarDatosProvision();
    },
    // Registra la extensión
    registrar() {
      if (!this.$store.getters.getregistro) {
        const debug = this.modoDebug;
        const videoTagRef = this.$refs.etiquetaVideo;
        const audioTagRef = this.$refs.etiquetaAudio;
        const sipPluginConfig = {
          sipIdentity: this.sipIdentity,
          displayName: this.displayName,
          sipRegistrar: this.sipRegistrar,
          username: this.username,
          secret: this.password,
        };

        this.myInstance = new PhoneCommander(
          sipPluginConfig,
          debug,
          videoTagRef,
          audioTagRef
        );
        this.myInstance.connect();
        this.$store.commit("SET_INSTANCIAPHONE", this.myInstance);
      }
    },
    estadoLlamada() {
      let estadoLLamada = this.$store.getters.getllamadaEstado;
      if (estadoLLamada == "accepted") {
        this.verBotonLlamar = false;
        this.verBotonColgarEnLlamada = true;
        this.verEtiquetaVideo = true;
       
      }
      if (estadoLLamada == "hangup") {
        this.verBotonLlamar = true;
        this.verBotonColgarEnLlamada = false;
      }
    },
  },
  computed: {
    VLlamadaEstado() {
      return this.$store.getters.getllamadaEstado;
    },
    Vregistered() {
      return this.$store.getters.getregistro;
    },
  },

  watch: {
    VLlamadaEstado(value) {
      if (value == "accepted") {
        this.verBotonLlamar = false;
        this.verBotonColgarEnLlamada = true;
        this.verEtiquetaVideo = true;
        this.verGiftEspera = false; 

      }
      if (value == "hangup") {
        this.verBotonLlamar = true;
        this.verBotonColgarEnLlamada = false;
      }
    },
    Vregistered() {
      if (this.$store.getters.getregistro) {
        // this.go("Registrado", "success");
      } else {
        this.loginProvision();
      }
    },
  },
  mounted() {
    this.loginProvision();
  },


};
</script>
